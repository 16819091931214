.record__session_container{
    display: flex;
    margin-top: 2px;
    margin-bottom: 10px;
    flex-direction: row;
    height: 14px;
}

.record_session_title {
    margin-bottom: 0px;
    font-size: 14px;
    color: #025959;
    font-weight: 700;
    margin-right: 8px;
}

.record_session_title2 {
    margin-bottom: 0px;
    font-size: 14px;
    color: #025959;
    font-weight: 700;
    margin-right: 8px;
}

.record_session_description {
    margin-bottom: 0px;
    font-size: 14px;
    color: #606060;
    font-weight: 500;
}

.record_session_description_underline {
    margin-bottom: 0px;
    font-size: 14px;
    color: #025959;
    font-weight: 500;
    text-decoration: underline;
}


.table_service_record {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    
.table_service {
    border: 1px solid #a3d7d7;
    width: 100%;
  }
    
.th_service {
    border-bottom: 1px solid #a3d7d7;
    border-right: 1px solid #a3d7d7;
    text-align: center;
}
    
.td_service {
    text-align: right;
    margin-left: 8px;
    height: 10px;
    font-size: 14px;
}

.td_container_items {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-bottom: 0px;
    justify-content: end;
    margin-right: 16px;
    height: 20px;
}

.icon__size{
    font-size: 12px;
    color: #025959;
    margin-left: 4px;
    margin-top: 2px;
}

.column__container{
    width: 50px;
}