.record__container{
    z-index: 0;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
}

.record_title {
    margin-bottom: 0px;
    font-size: 16px;
    color: #025959;
    font-weight: 700;
    margin-right: 8px;
}

.record_title_list {
    margin-bottom: 0px;
    font-size: 16px;
    color: #025959;
    font-weight: 850;
    margin-right: 8px;
}

.record_description {
    margin-bottom: 0px;
    font-size: 16px;
    color: #606060;
    font-weight: 500;
}

.item_record_row{
    display: flex;
    margin-top: 2px;
    margin-bottom: 4px;
    flex-direction: row;
}

.record_item_header {
    width: 100%;
    display: flex;
}

.last_item {
    margin-left: auto;
    margin-right: 16px;
}


.record__session_container{
    display: flex;
    margin-top: 2px;
    margin-bottom: 10px;
    flex-direction: row;
    height: 14px;
    width: 100%;
}

.record_session_title_provider {
    margin-bottom: 0px;
    font-size: 12px;
    color: #025959;
    font-weight: 700;
    margin-right: 8px;
    width: 115px;
}

.record_session_title_total {
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    margin-left: auto;
}

.record_session_description_provider {
    margin-bottom: 0px;
    font-size: 12px;
    color: #606060;
    font-weight: 500;
}

.record_session_title_summary {
    margin-bottom: 0px;
    font-size: 12px;
    color: #025959;
    font-weight: 700;
    margin-right: 8px;
    width: 140px;
}


.record_description_red {
    margin-bottom: 0px;
    font-size: 12px;
    margin-left: 120px;
    color: #d60000;

}