.title_period {
    margin-bottom: 0px;
    font-size: 14px;
    color: #025959;
    font-weight: 700;
    height: 20px;
}

.info__container{
    margin-top: 10px;
    margin-left: 10px;
}